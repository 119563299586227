<template>
  <div>
    <!--面包屑-->

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>病例管理</el-breadcrumb-item>
      <el-breadcrumb-item>病例列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="18">
          <el-form :inline="true">
            <el-form-item label="中医诊断">
              <el-input
                  clearable
                  placeholder="搜索中医诊断"
                  v-model="queryInfo.zhong_yi_zhen_duan"
              ></el-input>
            </el-form-item>
            <el-form-item label="西医诊断">
              <el-input
                  clearable
                  placeholder="搜索西医诊断"
                  v-model="queryInfo.xi_yi_zhen_duan"
              ></el-input>
            </el-form-item>
            <el-form-item label="医生签名">
              <el-input
                  clearable
                  placeholder="搜索医生签名"
                  v-model="queryInfo.yi_sheng_qian_ming"
              ></el-input>
            </el-form-item>

            <el-form-item label="姓名">
              <el-input
                  clearable
                  placeholder="搜索姓名"
                  v-model="queryInfo.name"
              ></el-input>
            </el-form-item>
            <!--            <el-form-item label="审核">-->
            <!--              <el-select v-model="check" clearable placeholder="请选择">-->
            <!--                <el-option-->
            <!--                    v-for="(item2, index2) in validArr"-->
            <!--                    :key="index2"-->
            <!--                    :label="item2"-->
            <!--                    :value="index2"-->
            <!--                >-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->

            <!--            <el-form-item label="证书版本">-->
            <!--              <el-select v-model="BingLiList_version" clearable placeholder="请选择">-->
            <!--                <el-option-->
            <!--                    v-for="(item2, index2) in BingLiListVersionArr"-->
            <!--                    :key="index2"-->
            <!--                    :label="item2"-->
            <!--                    :value="index2"-->
            <!--                >-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->

          </el-form>
        </el-col>
        <el-col :span="1.5">
          <el-button size="mini" round type="success" @click="getList"
          >搜索
          </el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button size="mini" round type="info" @click="reset()"
          >重置
          </el-button
          >
        </el-col>


        <el-col :span="1.5">
          <el-button size="mini" type="primary" round @click="onAdd">添加</el-button>
        </el-col>

        <el-col :span="1.5">
          <el-button type="danger" :disabled="multipleSelection.length<1" round @click="onDownAll">批量下载</el-button>
        </el-col>

      </el-row>

      <!--表格区-->
      <div>
        <el-table
            :border="true"
            :data="tableData"
            stripe
            :resizable="true"
            :height="getH()"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>

          <el-table-column
              show-overflow-tooltip
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <!--明细-->
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" size="" @click="onEditItem(scope.row)"
                >编辑
                </el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="onDelItem(scope.row)"
                >
                  删除
                </el-button
                >

                <el-button
                    type="text"
                    size=""
                    @click="onDown(scope.row)"
                >
                  下载
                </el-button
                >

              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!--分页区-->
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

    <!--编辑描述窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem.uid ? '新增' : '编辑'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propList"
              :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>

              <el-date-picker
                  v-else-if="item.type == 'date'"
                  v-model="editItem[item.prop]"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
              >
              </el-date-picker>
              <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :disabled="item.prop == 'id' || item.prop=='chu_zhi_yi_jian'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
              >
              </el-input>

              <MyUploadPlus
                  v-if="item.upload && item.upload==true "
                  accept="*"
                  :disabled="false"
                  @urlChange="(e) => onUrlChange(item.prop, e)"
              />

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //勾选行
      multipleSelection: [],
      //获取认证列表对象
      queryInfo: {
        zhong_yi_zhen_duan: "",
        xi_yi_zhen_duan: "",
        yi_sheng_qian_ming: "",
        name: "",
        //当前的页数
        page: 1,
        //当前的页码显示的条数
        pageSize: 10,
      },
      validArr: ["待审核", "通过", "退回"],
      //证书版本选项
      BingLiListVersionArr: ['默认', '第二版'],

      check: "",
      kc_id: "",
      uid: "",
      //总条数
      total: 0,
      //搜索词
      searchKey: "",
      //证书版本
      BingLiList_version: '',
      //认证管理列表
      tableData: [],

      //获取公司规模与行业分类对像
      ScaleData: {
        shai_type: "",
        shai_title: "",
        shai_option: "",
      },
      /**
       *  `id` int(11) NOT NULL AUTO_INCREMENT,
       *   `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
       *   `day` date DEFAULT NULL COMMENT '日期',
       *   `name` varchar(255) DEFAULT NULL COMMENT '姓名',
       *   `sex` varchar(255) DEFAULT '男' COMMENT '性别',
       *   `age` int(11) DEFAULT '0' COMMENT '年龄',
       *   `zheng_ci` varchar(255) DEFAULT '首诊' COMMENT '诊次',
       *   `zhu_su` text COMMENT '主诉',
       *   `xian_bing_shi` text COMMENT '现病史',
       *   `she_zhi` text COMMENT '舌质',
       *   `she_tai` text COMMENT '舌苔',
       *   `mai_xiang` text COMMENT '脉象',
       *   `she_mai_bu_chong` text COMMENT '补充',
       *   `xi_yan_shi` varchar(255) DEFAULT '否' COMMENT '吸烟史',
       *   `gao_xue_ya` varchar(255) DEFAULT '否' COMMENT '高血压史',
       *   `tang_niao_bing` varchar(255) DEFAULT '否' COMMENT '糖尿病史',
       *   `xin_zang_bing` varchar(255) DEFAULT '否' COMMENT '心脏病史',
       *   `shou_shu_wai_shang` varchar(255) DEFAULT '否' COMMENT '手术外伤史',
       *   `ji_wang_shi_bu_chong` text COMMENT '补充',
       *   `liu_xing_bing` text COMMENT '流行病学史',
       *   `GCP` text COMMENT 'GCP实验名称',
       *   `ti_wen` varchar(255) DEFAULT NULL COMMENT '体温',
       *   `mo_lu_ci` varchar(255) DEFAULT NULL COMMENT '脉率次',
       *   `hu_xi_pin_lu_ci` varchar(255) DEFAULT NULL COMMENT '呼吸频率次',
       *   `shou_suo_ya` varchar(255) DEFAULT NULL COMMENT '收缩压',
       *   `shu_zhang_ya` varchar(255) DEFAULT NULL COMMENT '舒张压',
       *   `ti_ge_bu_chong` text COMMENT '休格补充',
       *   `zhuan_ke` text COMMENT '专科情况',
       *   `fu_zu_jian_cha` text COMMENT '辅助检查',
       *   `zhong_yi_zhen_duan` text COMMENT '中医诊断',
       *   `xi_yi_zhen_duan` text COMMENT '西医诊断',
       *   `chu_zhi_yi_jian` text COMMENT '处置意见',
       *   `zhu_yi_si_xiang` text COMMENT '注意事项',
       *   `yi_sheng_qian_ming` varchar(255) DEFAULT NULL COMMENT '医生签名',
       *   `zhuan_ke_pic` text COMMENT '专科图片',
       *   `she_mai_pic` text COMMENT '舌脉图片',
       *   `fu_zu_jian_cha_pic` text COMMENT '辅助检查图片',
       *   `bz` varchar(255) DEFAULT NULL COMMENT '备注',
       */
      //prop="username" label="姓名"
      propList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 200,
          type: "time",
        },
        {
          prop: "day",
          label: "日期",
          width: 200,
        },
        {
          prop: "name",
          label: "姓名",
          width: 130,
        },
        {
          prop: "sex",
          label: "性别",
        },
        {
          prop: "age",
          label: "年龄",
        },
        {
          prop: "zheng_ci",
          label: "诊次",
        },
        {
          prop: "fuzheng_ci",
          label: "复诊次数",
        },
        {
          prop: "last_day",
          label: "上次就诊",
        },
        {
          prop: "yijian_add",
          label: "调整意见增加",
          width: 180,
        },
        {
          prop: "yijian_sub",
          label: "调整意见减少",
          width: 180,
        },
        {
          prop: "zhu_su",
          label: "主诉",
          width: 180,
          type: 'textarea'
        },
        {
          prop: "xian_bing_shi",
          label: "现病史",
          width: 180,
          type: "textarea",
        },
        {
          prop: "she_zhi",
          label: "舌质",
        },
        {
          prop: "she_tai",
          label: "舌苔",
        },
        {
          prop: "mai_xiang",
          label: "脉象",
        },
        {
          prop: "she_mai_bu_chong",
          label: "舌脉补充",
          width: 180,
          type: "textarea",
        },
        {
          prop: "xi_yan_shi",
          label: "吸烟史",
        },
        {
          prop: "gao_xue_ya",
          label: "高血压史",
        },
        {
          prop: "tang_niao_bing",
          label: "糖尿病史",
        },
        {
          prop: "xin_zang_bing",
          label: "心脏病史",
        },
        {
          prop: "shou_shu_wai_shang",
          label: "手术外伤史",
        },
        {
          prop: "ji_wang_shi_bu_chong",
          label: "既往史补充",
        },
        {
          prop: "liu_xing_bing",
          label: "流行病学史",
          width: 180,
          type: "textarea",
        },
        {
          prop: "GCP",
          label: "GCP实验名称",
          width: 180,
        },
        {
          prop: "ti_wen",
          label: "体温",
        },
        {
          prop: "mo_lu_ci",
          label: "脉率",
        },
        {
          prop: "hu_xi_pin_lu_ci",
          label: "呼吸频率",
        },
        {
          prop: "shou_suo_ya",
          label: "收缩压",
        },
        {
          prop: "shu_zhang_ya",
          label: "舒张压",
        },
        {
          prop: "ti_ge_bu_chong",
          label: "休格补充",
          width: 180,
          type: "textarea",
        },
        {
          prop: "zhuan_ke",
          label: "专科情况",
          width: 180,
          type: "textarea",
        },
        {
          prop: "fu_zu_jian_cha",
          label: "辅助检查",
          width: 180,
        },
        {
          prop: "zhong_yi_zhen_duan",
          label: "中医诊断",
          width: 180,
          type: "textarea",
        },
        {
          prop: "xi_yi_zhen_duan",
          label: "西医诊断",
          width: 180,
          type: "textarea",
        },
        {
          prop: "chu_zhi_yi_jian",
          label: "处置意见",
          width: 180,
          type: "textarea",
        },
        {
          prop: "zhu_yi_si_xiang",
          label: "注意事项",
          width: 180,
        },
        {
          prop: "yi_sheng_qian_ming",
          label: "医生签名",
        },
        {
          prop: "zhuan_ke_pic",
          label: "专科图片",
          width: 180,
          type: 'pic'
        },
        {
          prop: "she_mai_pic",
          label: "舌脉图片",
          width: 180,
          type: 'pic'

        },
        {
          prop: "fu_zu_jian_cha_pic",
          label: "辅助检查图片",
          width: 180,
          type: 'pic'
        },
        {
          prop: "bz",
          label: "备注",
          width: 180,
        }
      ],
      //Drawer 抽屉显示与关闭
      drawer: false,
      showRowIndex: -1,
      showItem: null,

      //显示审核订单
      showCheckDlg: false,
      //审核订单内容
      checkItem: null,

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  computed: {},
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 330;
    },
    //明细表单
    getLabel(prop) {
      //console.log('prop:'+prop)
      for (let i in this.propList) {
        if (prop == this.propList[i]["prop"]) {
          return this.propList[i]["label"];
        }
      }
    },

    //点击明细弹出页面 Drawer 抽屉
    onShowRow(item, index) {
      console.log(index);
      this.showRowIndex = index;
      this.showItem = JSON.parse(JSON.stringify(item));
      console.log(item);
      this.drawer = true;
    },


    //获取认证列表
    async getList() {
      let obj = {
        ...this.queryInfo
      };
      const res = await this.$post(`BingLiList/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;

      this.total = res.data.total;
    },
    reset() {
      this.queryInfo.zhong_yi_zhen_duan = ''
      this.queryInfo.xi_yi_zhen_duan = ''
      this.queryInfo.yi_sheng_qian_ming = ''
      this.queryInfo.name = ''
      this.getList()
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      //刷新认证列表数据
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.page = newPage;
      //刷新认证列表数据
      this.getList();
    },
    //先判断选择为 是 编辑 还是 添加
    //如果gid > 0是编辑, 则为添加
    // 展示编辑角色对话框 Dialog:对话框
    onEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },

    //点击 确定 发起网络请求
    async onSaveEdit() {
      let obj1 = {
        id: this.editItem.id,
        data: {
          ...this.editItem,
        },
      };
      const res = await this.$post(`BingLiList/saveData`, obj1);
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
      this.showEditDlg = false;
    },
    //删除用户ID
    async onDelItem(item) {
      //弹出对话框是否真的删除
      const confirmResult = await this.$confirm(
          "此操作将永久删除记录?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj = {
        id: item.id,
      };
      const res = await this.$post(`BingLiList/delItem`, obj);
      console.log("确认删除", res);

      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //审核窗口关态
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onUrlChange(fielddName, e) {
      console.log("获取 getUrlChange", fielddName, e);
      this.editItem[fielddName] = e[0].url;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    //批量下载
    onDownAll() {
      if (this.multipleSelection.length <= 0) {
        this.$err("请选择要下载的病历")
        return
      }
      this.$confirm(`是否下载${this.multipleSelection.length}份病历`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.callDowmList(this.multipleSelection)
      }).catch(() => {
        this.$err('已取消下载')
      })
    },
    async callDowmList(list) {
      let idArr=[]
      for (let i in list) {
        idArr.push(list[i].id)
      }
      const res = await this.$post(`BingLiList/downList`, {
        ids: idArr.join(','),
      });
      console.log(res);
      if (res.code !== 200) return;
      //得到下载地址
      let url = res.data
      window.open(url)
    },
    //下载病历
    onDown(item) {
      console.log(item)
      this.$confirm(`是否下载病历`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$ok('下载成功')
        this.callDowmItem(item)
      }).catch(() => {
        this.$err('已取消下载')
      })
    },
    async callDowmItem(item) {
      const res = await this.$post(`BingLiList/downItem`, {
        id: item.id,
      });
      console.log(res);
      if (res.code !== 200) return;
      //得到下载地址
      let url = res.data
      window.open(url)
    }
  },
};
</script>

<style lang="less" scoped>
.impleName {
  max-width: 100%; /*最大宽度为当前元素的100%*/
  display: inline-block;
  white-space: nowrap;
  /*保证文本内容不会自动换行，如果多余的内容会在水平方向撑破单元格*/
  overflow: hidden; /*隐藏超出单元格的部分*/
  text-overflow: ellipsis; /*将被隐藏的那部分用省略号代替*/
}

.saveDiv {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.tabDiv {
  display: flex;
  justify-content: space-between;
}

.fDiv {
  div {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-tag {
      flex-basis: 1;
      width: 120px !important;
      margin-right: 10px;
    }

    div {
      flex-grow: 1;
    }
  }
}

.drawerDiv {
  overflow-y: auto;
  height: 98vh;
  padding: 5px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.checkItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    width: 150px;
  }
}
</style>