<template>
  <div>
    <!--面包屑-->

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>证书管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="18">
          <el-form :inline="true">
            <el-form-item label="关键词搜索">
              <el-input
                  clearable
                  placeholder="关键词搜索"
                  v-model="searchKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户ID">
              <el-input
                  clearable
                  placeholder="搜索用户ID"
                  v-model="uid"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程ID">
              <el-input
                  clearable
                  placeholder="搜索课程ID"
                  v-model="kc_id"
              ></el-input>
            </el-form-item>
            <el-form-item label="审核">
              <el-select v-model="check" clearable placeholder="请选择">
                <el-option
                    v-for="(item2, index2) in validArr"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="证书版本">
              <el-select v-model="cert_version" clearable placeholder="请选择">
                <el-option
                    v-for="(item2, index2) in certVersionArr"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
        </el-col>
        <el-col :span="3">
          <el-button size="mini" round type="success" @click="getList"
          >搜索
          </el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="primary" round @click="onAdd">添加</el-button>
        </el-col>
      </el-row>

      <!--表格区-->
      <div>
        <el-table
            :border="true"
            :data="tableData"
            stripe
            :resizable="true"
            :height="getH()"
        >
          <el-table-column
              show-overflow-tooltip
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <!--明细-->
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" size="" @click="onEditItem(scope.row)"
                >编辑
                </el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="onDelItem(scope.row)"
                >
                  删除
                </el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!--分页区-->
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

    <!--编辑描述窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem.uid ? '新增' : '编辑'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propList"
              :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>

              <el-date-picker
                  v-else-if="item.type == 'date'"
                  v-model="editItem[item.prop]"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
              >
              </el-date-picker>
              <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :disabled="item.prop == 'vid'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
              >
              </el-input>

              <MyUploadPlus
                  v-if="item.upload && item.upload==true "
                  accept="*"
                  :disabled="false"
                  @urlChange="(e) => onUrlChange(item.prop, e)"
              />

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //获取认证列表对象
      queryInfo: {
        query: "",
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
      },
      validArr: ["待审核", "通过", "退回"],
      //证书版本选项
      certVersionArr: ['默认', '第二版'],

      check: "",
      kc_id: "",
      uid: "",
      //总条数
      total: 0,
      //搜索词
      searchKey: "",
      //证书版本
      cert_version:'',
      //认证管理列表
      tableData: [],

      //获取公司规模与行业分类对像
      ScaleData: {
        shai_type: "",
        shai_title: "",
        shai_option: "",
      },
      /**
       * `zy_cert`
       *  `id` int(11) NOT NULL AUTO_INCREMENT,
       `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '申请时间',
       `uid` int(11) NOT NULL DEFAULT 0 COMMENT '用户ID',
       `kc_id` int(11) NOT NULL DEFAULT 0 COMMENT '课程ID',
       `check` int(11) NOT NULL DEFAULT 0 COMMENT '审核(0待审核,1通过,2退回)',
       `err_msg` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '退回原因',
       `check_time` timestamp NULL DEFAULT NULL COMMENT '审核时间',
       `cert_num` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '证书编号',
       `cert_name` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '项目名称',
       `cert_time` timestamp NULL DEFAULT NULL COMMENT '证书生成时间',

       `uImges` varchar(1024) NOT NULL DEFAULT '' COMMENT '证书头像',
       `uName` varchar(255) NOT NULL DEFAULT '' COMMENT '证书用户姓名',
       `idCard` varchar(255) NOT NULL DEFAULT '' COMMENT '证书用户身份证号',
       `startTime` date DEFAULT NULL COMMENT '证书培训开始',
       `endTime` date DEFAULT NULL COMMENT '证书培训结束',
       `certHour` int(11) NOT NULL DEFAULT '0' COMMENT '证书培训学时',
       `cert_version` int(11) NOT NULL DEFAULT '0' COMMENT '证书版本',
       */
      //prop="username" label="姓名"
      propList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "申请时间",
          width: 200,
          type: "time",
        },
        {
          prop: "uid",
          label: "用户ID",
          width: 130,
        },
        {
          prop: "wx_nick",
          label: "用户微信",
        },
        {
          prop: "kc_id",
          label: "课程ID",
        },
        {
          prop: "kc_name",
          label: "课程名称",
          width: 180,
        },
        {
          prop: "check",
          label: "审核",
          type: "array",
          data: ["待审核", "通过", "退回"],
        },

        {
          prop: "err_msg",
          label: "退回原因",
          width: 180,
        },
        {
          prop: "check_time",
          label: "审核时间",
          width: 180,
          type: "time",
        },
        {
          prop: "cert_version",
          label: "证书版本",
          width: 200,
          type: 'array',
          data:  ['默认', '第二版']
        },
        {
          prop: "cert_num",
          label: "证书编号",
          width: 200,
        },
        {
          prop: "cert_name",
          label: "项目名称",
          width: 200,
        },
        {
          prop: "cert_time",
          label: "证书生成时间",
          width: 180,
          type: "time",
        },
        {
          prop: "uImges",
          label: "证书头像",
          width: 180,
          type: "pic",
          upload: true,
        },
        {
          prop: "uName",
          label: "证书用户姓名",
          width: 180,
        },
        {
          prop: "idCard",
          label: "证书用户身份证号",
          width: 180,
        },
        {
          prop: "startTime",
          label: "证书培训开始",
          width: 180,
          type: "date",
        },
        {
          prop: "endTime",
          label: "证书培训结束",
          width: 180,
          type: "date",
        },
        {
          prop: "certHour",
          label: "证书培训学时",
          width: 180,
        },
      ],
      //Drawer 抽屉显示与关闭
      drawer: false,
      showRowIndex: -1,
      showItem: null,

      //显示审核订单
      showCheckDlg: false,
      //审核订单内容
      checkItem: null,

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  computed: {},
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 330;
    },
    //明细表单
    getLabel(prop) {
      //console.log('prop:'+prop)
      for (let i in this.propList) {
        if (prop == this.propList[i]["prop"]) {
          return this.propList[i]["label"];
        }
      }
    },

    //点击明细弹出页面 Drawer 抽屉
    onShowRow(item, index) {
      console.log(index);
      this.showRowIndex = index;
      this.showItem = JSON.parse(JSON.stringify(item));
      console.log(item);
      this.drawer = true;
    },


    //获取认证列表
    async getList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,

        uid: this.uid,
        kc_id: this.kc_id,
        check: this.check,
        searchKey: this.searchKey,
        cert_version:this.cert_version,
      };
      const res = await this.$post(`Cert/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      //刷新认证列表数据
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //刷新认证列表数据
      this.getList();
    },
    //先判断选择为 是 编辑 还是 添加
    //如果gid > 0是编辑, 则为添加
    // 展示编辑角色对话框 Dialog:对话框
    onEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },

    //点击 确定 发起网络请求
    async onSaveEdit() {
      let obj1 = {
        id: this.editItem.id,
        data: {
          ...this.editItem,
        },
      };
      const res = await this.$post(`Cert/saveData`, obj1);
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
      this.showEditDlg = false;
    },
    //删除用户ID
    async onDelItem(item) {
      //弹出对话框是否真的删除
      const confirmResult = await this.$confirm(
          "此操作将永久删除记录?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj = {
        id: item.id,
      };
      const res = await this.$post(`Cert/delItem`, obj);
      console.log("确认删除", res);

      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //审核窗口关态
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onUrlChange(fielddName, e) {
      console.log("获取 getUrlChange", fielddName, e);
      this.editItem[fielddName] = e[0].url;
    },
  },
};
</script>

<style lang="less" scoped>
.impleName {
  max-width: 100%; /*最大宽度为当前元素的100%*/
  display: inline-block;
  white-space: nowrap;
  /*保证文本内容不会自动换行，如果多余的内容会在水平方向撑破单元格*/
  overflow: hidden; /*隐藏超出单元格的部分*/
  text-overflow: ellipsis; /*将被隐藏的那部分用省略号代替*/
}

.saveDiv {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.tabDiv {
  display: flex;
  justify-content: space-between;
}

.fDiv {
  div {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-tag {
      flex-basis: 1;
      width: 120px !important;
      margin-right: 10px;
    }

    div {
      flex-grow: 1;
    }
  }
}

.drawerDiv {
  overflow-y: auto;
  height: 98vh;
  padding: 5px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.checkItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    width: 150px;
  }
}
</style>