<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>学习视频</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row type="flex" align="top">
        <el-col :span="8">
          <!--搜索与添加-->
          <el-form :inline="true" class="searchDiv">
            <el-form-item label="关键词">
              <el-input
                clearable
                placeholder="搜索关键词"
                v-model="searchKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程ID">
              <el-input
                clearable
                placeholder="搜索课程ID"
                v-model="kc_id"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <!--显示视频URL-->
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[scope.column["property"]] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.id ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.prop == 'power'"
                v-model="editItem[item.prop]"
                :collapse-tags="false"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in powerList"
                  :key="index2"
                  :label="item2"
                  :value="item2"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
              <MyUploadPlus
                v-if="item.prop == 'video_url'"
                accept="*"
                :disabled="false"
                @urlChange="(e) => onUrlChange(item.prop, e)"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,

      searchKey: "",
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      kc_id: "",
      /**
  `id` int(11) NOT NULL AUTO_INCREMENT,
  `kc_id` int(11) NOT NULL DEFAULT '0' COMMENT '课程ID',
  `order_id` int(11) NOT NULL DEFAULT '0' COMMENT '排序ID',
  `video_title` varchar(255) NOT NULL DEFAULT '' COMMENT '视频标题',
  `video_url` varchar(255) NOT NULL DEFAULT '' COMMENT '视频URL',
  `duration` int(11) NOT NULL DEFAULT '0' COMMENT '时长',
  `vodId` varchar(255) NOT NULL DEFAULT '' COMMENT '播放ID',
  `no_need` int(11) NOT NULL DEFAULT '0' COMMENT '非必学',
       * 
       */
      propList: [
        {
          prop: "id",
          label: "ID",
          width: 80,
        },

        {
          prop: "kc_id",
          label: "课程ID",
        },
        {
          prop: "kc_name",
          label: "课程名称",
          width: 400,
        },
        {
          prop: "order_id",
          label: "排序从小到大",
          width: 150,
        },
        {
          prop: "video_title",
          label: "视频标题",
          width: 400,
        },

        {
          prop: "duration",
          label: "时长",
        },
        {
          prop: "is_mianfei",
          label: "是否免费",
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "no_need",
          label: "非必学",
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "video_url",
          label: "视频URL",
          width: 200,
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
      //所有权限列表
      powerList: [],
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 330;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        searchKey: this.searchKey,
        kc_id: this.kc_id,
      };
      const res = await this.$post(`Video/getList`, obj);
      console.log(res, "jjjjj");
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));

      this.showEditDlg = true;
    },

    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存信息
    async onSaveEdit() {
      let obj = {
        id: this.editItem.id,
        data: this.editItem,
      };

      const res = await this.$post(`Video/saveData`, obj);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$info("已取消删除");
      }
      let obj = {
        id: item.id,
      };
      console.log(obj);
      const res = await this.$post(`Video/delItem`, obj);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    onUrlChange(fielddName, e) {
      console.log("获取 getUrlChange", fielddName, e);
      this.editItem[fielddName] = e[0].url;

      var audio = new Audio(this.editItem[fielddName]);
      // 元数据已加载
      let that = this;
      audio.addEventListener("loadedmetadata", function (e) {
        that.editItem["duration"] = parseInt(audio.duration);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
