<template>
  <el-container class="home-container">
    <!--头部区域-->
    <el-header>
      <div style="display: flex">
        <img
          class="tbl"
          src="../assets/zy1.png"
          alt=""
          style="height: 40px; background-color: #373d41"
        />
        <span>中医课程后台管理系统</span>
        <div id="myTabs">
          <el-tabs
            @tab-click="tabClick"
            @tab-remove="tabRemove"
            @contextmenu.prevent.native="onPopMenu($event)"
            v-model="tabVal"
            type="card"
            :stretch="false"
          >
            <el-tab-pane
              :key="item.name"
              v-for="item in editableTabs"
              :label="item.title"
              :name="item.name"
              :closable="editableTabs.length > 1"
            >
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!--右键菜单-->
    <ul
      v-show="contextMenuVisible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <!-- <li @click="closeAllTabs">关闭所有</li> -->
      <li @click="closeOtherTabs('left')">关闭左边</li>
      <li @click="closeOtherTabs('right')">关闭右边</li>
      <li @click="closeOtherTabs('other')">关闭其他</li>
    </ul>
    <!--主体区域-->
    <el-container style="height: 350px">
      <!--左侧边栏-->
      <el-aside :width="iscollapse ? '69px' : '240px'">
        <div class="toggle-button" @click="togglecollapse">|||</div>
        <!--搜索区域-->
        <div class="searchDiv">
          <el-input
            v-model="searchMenu"
            size="mini"
            clearable
            placeholder="搜索菜单"
            >></el-input
          >
        </div>
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409eff"
          :unique-opened="true"
          :collapse="iscollapse"
          :collapse-transition="true"
          router
          :default-active="activepath"
          @open="onMenuOpen"
        >
          <!--一级菜单
           :index="item.authName"
          -->
          <el-submenu
            :index="item.authName"
            v-for="(item, index_sub) in menulist"
            :key="index_sub"
          >
            <template slot="title">
              <!--图标-->
              <i :class="item.icon"></i>
              <!--文本-->
              <span>{{ item.authName }}</span>
            </template>
            <!--二级菜单-->
            <el-menu-item
              :index="'/' + subItem.path"
              v-for="(subItem, index2) in item.children"
              :key="index2 + index_sub * 100"
              @click="saveNavstats('/' + subItem.path, subItem)"
            >
              <template slot="title">
                <!--图标-->
                <i class="el-icon-menu"></i>
                <!--文本-->
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--右侧边栏主体-->
      <el-main>
        <keep-alive :include="includeTabsArr">
          <!--路由站位符-->
          <router-view></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from "vuex"; // 引入mapState
export default {
  data() {
    return {
      //搜索菜单功能
      searchMenu: "",
      //左侧菜单数据
      menulistBack: [
        {
          authName: "用户管理",
          icon: "el-icon-user",
          children: [
            {
              authName: "已购课程",
              path: "BuyList",
            },
            {
              authName: "前台用户",
              path: "User",
            },
            {
              authName: "充值订单",
              path: "ChongList",
            },

            {
              authName: "用户行为",
              path: "Behavior",
            },
          ],
        },
        {
          authName: "课程管理",
          icon: "el-icon-user-solid",
          children: [
            {
              authName: "课程列表",
              path: "KcList",
            },
            {
              authName: "学习视频",
              path: "Video",
            },
            {
              authName: "试卷题目",
              path: "KaoShiTiMu",
            },

            {
              authName: "学习记录",
              path: "Study",
            },
            {
              authName: "考试记录",
              path: "KaoshiLog",
            },
            {
              authName: "证书管理",
              path: "Cert",
            },
          ],
        },
        {
          authName: "系统管理",
          icon: "el-icon-s-tools",
          children: [
            {
              authName: "系统设置",
              path: "Set",
            },
            {
              authName: "后台用户",
              path: "AdminUser",
            },
          ],
        },
        {
          authName: "病例管理",
          icon: "el-icon-document",
          children: [
            {
              authName: "病例列表",
              path: "BingLiList",
            },
          ],
        },
      ],
      menulist: [],
      //是否折叠
      iscollapse: false,
      //被激活的地址
      activepath: "",
      //顶部标签组
      //editableTabs: [],
      //当前显示的标签内容
      //editableTabsValue: "",
      tabVal: "",
      //右键菜单相关
      contextMenuVisible: false,
      left: 0,
      top: 0,
      //当前右键选中的项目
      contextMenuTabName: "",
    };
  },
  computed: {
    ...mapState(["editableTabs", "editableTabsValue"]),
    includeTabsArr: function (state) {
      let arr = [];
      state.editableTabs.forEach((element) => {
        arr.push(element.name);
      });
      console.log("includeTabsArr", arr);
      return arr;
    },
  },

  created() {
    //初始化菜单
    this.initMenulist();
    console.log("当前路由", this.$route);
    if (this.editableTabsValue) {
      this.onChangeEditableTabsValue(this.editableTabsValue);
    }
  },
  watch: {
    //搜索词改变
    searchMenu(val) {
      console.log(val);
      if (!val || val.length < 1) {
        this.menulist = JSON.parse(JSON.stringify(this.menulistBack));
        return;
      } else {
        this.menulist = [];
        for (let i in this.menulistBack) {
          //查找子项
          let childrenArr = [];
          for (let j in this.menulistBack[i]["children"]) {
            if (
              this.menulistBack[i]["children"][j]["authName"].indexOf(val) > -1
            ) {
              childrenArr.push(
                JSON.parse(JSON.stringify(this.menulistBack[i]["children"][j]))
              );
            }
          }
          if (childrenArr.length > 0) {
            //添加一级
            let obj = JSON.parse(JSON.stringify(this.menulistBack[i]));
            obj["children"] = childrenArr;
            this.menulist.push(obj);
          }
        }
      }
    },
    //保存到本地
    editableTabs(val) {
      //window.localStorage.setItem("editableTabs", JSON.stringify(val));
    },
    //当前选项值改变
    editableTabsValue(val) {
      this.onChangeEditableTabsValue(val);
    },
    //右键菜单没有操作时
    contextMenuVisible(value) {
      if (this.contextMenuVisible) {
        document.body.addEventListener("click", this.closeContextMenu);
      } else {
        document.body.removeEventListener("click", this.closeContextMenu);
      }
    },
  },
  methods: {
    //当前选项值改变
    onChangeEditableTabsValue(val) {
      console.log("链接改变:" + val);
      if (!val) return;
      this.tabVal = val;
      this.activepath = "/" + val;
      //跳到此组件
      if (this.$route.name == val) {
        console.log("链接一样，不跳转:" + this.activepath);
        return;
      }
      //跳转
      this.$router.push(this.activepath);
      console.log("跳转到:" + this.activepath);
    },
    //初始化菜单
    initMenulist() {
      this.menulist = JSON.parse(JSON.stringify(this.menulistBack));
    },
    getPowerList() {
      console.log("-----权限列表--开始----");
      let arr = [];
      let str = "";
      for (let i in this.menulistBack) {
        let mainAuthName = this.menulistBack[i]["authName"];

        let childrenList = this.menulistBack[i]["children"];
        //console.log(i, authName, childrenList);

        for (let j in childrenList) {
          //console.log(j, childrenList[j])
          let obj = childrenList[j];

          console.log(obj);
          arr.push({
            ...obj,
            mainAuthName,
          });
          str += mainAuthName + "|" + obj.authName + "|" + obj.path + "\r\n";
        }
      }
      console.log("-----权限列表----");
      console.log(arr);
      console.log(str);
    },
    logout() {
      this.$router.push("/");
    },
    //点击按扭 ，切换菜单展开和折叠
    togglecollapse() {
      this.iscollapse = !this.iscollapse;
    },
    //保存连接的激活状态
    saveNavstats(activepath, menuItem = null) {
      window.sessionStorage.setItem("activepath", activepath);
      this.activepath = activepath;
      console.log("连接", activepath, menuItem);
      //添加标签页
      if (menuItem) {
        this.addToTabs(menuItem);
      }
    },
    //sub-menu 展开的回调
    //index: 打开的 sub-menu 的 index， indexPath: 打开的 sub-menu 的 index path
    onMenuOpen(index, indexPath) {
      console.log("菜单选中", index, indexPath);
      this.saveNavstats(index);
    },
    //添加到标签页
    addToTabs(menuItem = null) {
      if (!menuItem) return;
      //如果存在，则选中它
      let index = this.editableTabs.findIndex((item) => {
        return item.name == menuItem.path;
      });
      if (index == -1) {
        /*
        this.editableTabs.push({
          name: menuItem.path,
          title: menuItem.authName,
        });
        */
        this.$store.commit("addItem", {
          name: menuItem.path,
          title: menuItem.authName,
        });
        index = this.editableTabs.length - 1;
      }
      //选中
      //this.editableTabsValue = this.editableTabs[index].name;
      this.$store.commit("setTabsValue", this.editableTabs[index].name);
      console.log(this.editableTabs);
      /*
      window.localStorage.setItem(
        "editableTabs",
        JSON.stringify(this.editableTabs)
      );
      */
    },
    tabClick(item) {
      this.$store.commit("setTabsValue", item.name);
    },
    //移除标签
    tabRemove(name) {
      console.log(name);
      if (this.editableTabs.length < 1) {
        //最后一个不删除
        return;
      }
      let index = this.editableTabs.findIndex((item) => {
        return item.name == name;
      });
      if (index > -1) {
        //this.editableTabs.splice(index, 1);
        this.$store.commit("delItem", name);
      }
      /*
      window.localStorage.setItem(
        "editableTabs",
        JSON.stringify(this.editableTabs)
      );
      */

      //如果是删除的当前选中项，则把当前项改为前一个
      if (name == this.editableTabsValue) {
        //向前移动一项
        index = index - 1;
        if (index < 0) {
          index = 0;
        } else if (index > this.editableTabs.length - 1) {
          //如果当前项是最后一个
          index = this.editableTabs.length - 1;
        }
        // this.editableTabsValue = this.editableTabs[index].name;
        this.$store.commit("setTabsValue", this.editableTabs[index].name);
      }
    },
    //右键菜单
    onPopMenu(event) {
      console.log("右键菜单", event);
      if (this.editableTabs.length < 2) return;
      //console.log(e.srcElement);
      if (event.srcElement.id) {
        //当前右键的name
        let name = event.srcElement.id.split("-")[1];
        this.contextMenuVisible = true;
        this.contextMenuTabName = name; //记录下当前右键选中的项目
        this.left = event.clientX;
        this.top = event.clientY + 10;
      }
    },
    // 关闭contextMenu
    closeContextMenu() {
      this.contextMenuVisible = false;
    },
    //关闭标签
    closeOtherTabs(val) {
      console.log(val);
      //当前右键选中的项目
      let name = this.contextMenuTabName;
      let index = this.editableTabs.findIndex((item) => {
        return item.name == name;
      });
      if (val == "left") {
        //关闭左边
        if (index > 0) {
          for (let i = 0; i < index; i++) {
            this.$store.commit("delItem", this.editableTabs[0].name);
          }
        }
      }
      if (val == "right") {
        //关闭右边
        if (index < this.editableTabs.length - 1) {
          for (let i = this.editableTabs.length - 1; i > index; i--) {
            this.$store.commit("delItem", this.editableTabs[i].name);
          }
        }
      }

      if (val == "other") {
        //关闭其他

        //关闭右边
        if (index < this.editableTabs.length - 1) {
          for (let i = this.editableTabs.length - 1; i > index; i--) {
            this.$store.commit("delItem", this.editableTabs[i].name);
          }
        }
        //关闭左边
        if (index > 0) {
          for (let i = 0; i < index; i++) {
            console.log("准备删除:" + this.editableTabs[0].name);
            this.$store.commit("delItem", this.editableTabs[0].name);
          }
        }
        //跳到当前右键选中的组件
        this.$store.commit("setTabsValue", name);
      }
    },
    //关闭所有标签
    closeAllTabs() {
      this.$store.commit("clearAll");
    },
  },
};
</script>
<style lang="less">
.el-header {
  height: 30px !important;
}

.el-button {
  display: flex !important;
  height: 30px !important;
  align-items: center !important;
}
#myTabs {
  width: 80vw;

  margin-left: 25px;
  padding-top: 10px;
  .el-tabs__item {
    background: white;
  }
}
</style>
<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  //height: 30px;
  background-color: #373d41;
  display: flex; //新式布局
  justify-content: space-between; //左右靠边
  padding-left: 0;
  align-items: center; //纵向居中对齐
  color: #ffff;
  font-size: 18px;

  > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.toggle-button {
  background-color: #4a5064;
  height: 20px;
  text-align: center;
  font-size: 16px; //字体大小
  line-height: 20px; //居中对齐
  color: #ffff;
  letter-spacing: 0.2em; //字体宽度
  cursor: pointer; //鼠标放上去变小手
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.iconsObj {
  margin-right: 10px;
}

.tbl {
  background-color: #333744;
}
.searchDiv {
  margin: 10px 5px;
}

.contextmenu {
  width: 100px;
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
  margin: 0;
  padding: 7px 16px;
}
.contextmenu li:hover {
  background: #f2f2f2;
  cursor: pointer;
}
</style>
