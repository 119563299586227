<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>充值订单</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="20">
          <el-form :inline="true">
            <el-form-item label="关键词搜索">
              <el-input
                placeholder="请输入搜索内容"
                v-model="searchKey"
                clearable
                width="40"
              >
                <!--实现搜索功能  v-model：实现数据双向绑定，clearable 清空文本框，clear：在点击由 clear属性生成的清空按钮时触发-->
              </el-input>
            </el-form-item>
            <el-form-item label="充值用户ID">
              <el-input
                clearable
                placeholder="搜索充值用户ID"
                v-model="uid"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程ID">
              <el-input
                clearable
                placeholder="搜索课程ID"
                v-model="kc_id"
              ></el-input>
            </el-form-item>

            <el-form-item label="支付类型">
              <el-select v-model="zhifu_type" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in zhifu_typeArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否支付">
              <el-select v-model="is_ok" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in is_okArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <img
                v-else-if="scope.column['property'] == 'user_img'"
                :src="scope.row['user_img_val']"
                width="20"
                height="20"
              />

              <div v-else>
                {{ scope.row[scope.column["property"]] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.id ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.prop == 'power'"
                v-model="editItem[item.prop]"
                :collapse-tags="false"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in powerList"
                  :key="index2"
                  :label="item2"
                  :value="item2"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,

      zhifu_type: "",
      is_ok: "",
      zhifu_typeArr: ["微信", "支付宝"],
      is_okArr: ["待支付", "成功", "失败"],

      searchKey: "",
      uid: "",
      kc_id: "",
      //用户数据列表 默认为空
      tableData: [],

      //总数据条数
      total: 0,
      /**
       * 
  `id` int(11) NOT NULL AUTO_INCREMENT,
  `mainAuthName` varchar(255) NOT NULL DEFAULT '' COMMENT '模块名称',
  `authName` varchar(255) NOT NULL DEFAULT '' COMMENT '权限名称',
  `path` varchar(255) NOT NULL DEFAULT '' COMMENT '类名称',
       * 
       */
      propList: [
        {
          prop: "id",
          label: "ID",
          width: 80,
        },
        {
          prop: "is_ok",
          label: "支付状态",
          type: "array",
          data: ["待支付", "成功", "失败"],
        },
        {
          prop: "zhifu_type",
          label: "支付类型",
          type: "array",
          data: ["微信", "支付宝"],
        },
        {
          prop: "kc_id",
          label: "课程ID",
          width: 80,
        },

        {
          prop: "kc_name",
          label: "课程名称",
          width: 200,
        },
        {
          prop: "money",
          label: "课程单价",
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 180,
          type: "time",
        },
        {
          prop: "uid",
          label: "充值用户ID",
        },
        {
          prop: "wx_nick",
          label: "用户微信",
        },
        {
          prop: "order_id",
          label: "充值订单号",
          width: 200,
        },
        {
          prop: "add_num",
          label: "充值金额",
          width: 150,
        },
        {
          prop: "remark",
          label: "订单说明信息",
          width: 250,
        },

        {
          prop: "create_msg",
          label: "生成支付说明",
          width: 250,
        },
        {
          prop: "pay_user_id",
          label: "支付用户账号",
          width: 200,
        },
        {
          prop: "pay_order",
          label: "支付交易号",
          width: 200,
        },
        {
          prop: "pay_oktime",
          label: "支付完成时间",
          width: 200,
        },
        {
          prop: "pay_payment_price",
          label: "支付成功金额",
          width: 200,
        },
        {
          prop: "need_price",
          label: "金额差距",
        },
        {
          prop: "notify_json",
          label: "通知信息",
          width: 200,
        },

        {
          prop: "bz",
          label: "备注",
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
      //所有权限列表
      powerList: [],
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 240;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        searchKey: this.searchKey,
        quid: this.uid,
        kc_id: this.kc_id,
        zhifu_type:this.zhifu_type,
        is_ok:this.is_ok
      };
      const res = await this.$post(`ChongList/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));

      this.showEditDlg = true;
    },

    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存信息
    async onSaveEdit() {
      let obj = {
        id: this.editItem.id,
        data: this.editItem,
      };

      const res = await this.$post(`ChongList/saveData`, obj);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$info("已取消删除");
      }
      let obj = {
        id: item.id,
      };
      console.log(obj);
      const res = await this.$post(`ChongList/delItem`, obj);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
